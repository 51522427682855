import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


export default function CustomDND (props) {


    return (
        <div>
            <p>The board will go here.</p>
        </div>
    )


};