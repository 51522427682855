// For pages_discussion

const DiscussionHome = () => {
    return ( 
    <div>
      <h1>Discussion Home</h1>
    </div>)
    ;
  };
  
  export default DiscussionHome;
  