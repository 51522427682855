import './AiObjects.css'

const AiObjects = () => {
    return (
    <div>
      <h1>Ai Objects</h1>

      <div class ="blockzone">
      </div>

      <img class='MlHomepage' src={process.env.PUBLIC_URL + './machineLearningImage.PNG'}/>
    </div>);
  };
  
  export default AiObjects;
  